import React from 'react';

const TruncatedText = ({ text }) => {
  const appendEllipsisAfterNthWord = (input) => {
    const words = input.split(/\s+/);
    const wordCount = words.length;

    if (wordCount <= 20) {
      return input;
    } else {
      const first20Words = words.slice(0, 20).join(' ');
      return `${first20Words} [...]`;
    }
  };

  const truncatedText = appendEllipsisAfterNthWord(text);

  return <p>{truncatedText}</p>;
};

export default TruncatedText;

 export const truncatedTextFn = ( text ) => {
  const appendEllipsisAfterNthWord = (input) => {
    const words = input.split(/\s+/);
    const wordCount = words.length;

    if (wordCount <= 20) {
      return input;
    } else {
      const first20Words = words.slice(0, 20).join(' ');
      return `${first20Words} [...]`;
    }
  };

  const truncatedText = appendEllipsisAfterNthWord(text);
  return {truncatedText};
};