import React from "react";
import TrImage from "../../images/banner-slider__image/visit_to_mce.jpg";

function AboutText() {
  return (
    // <section className="agr-section container">
    <section className="">
      <div className="about-container bg-light mt-6">
        <div className="row justify-content-center mb-5 pb-2">
          <div className=" text-center heading-section pt-5">
            <h3 className="fw-bolder fw-bold">
              <span className="header text-info">Who We Are</span>
            </h3>
            <h2 className="mb-4">About Us</h2>
            <div className="abt-content pb-5">
              <div className="image">
                <img src={TrImage} alt="Training Pictures" />
              </div>
              <div className="text abt-text">
                <p>
                  <span className="header text-info">Agrico Hub </span>
                  is a Business and Digital Innovation Hub that uses Innovative
                  Business Development and Digital Technology solutions to
                  support early-stage entrepreneurs, and Small & Growing
                  Businesses (SGBs) in Agribusiness, Trade & Commerce, and
                  Service providers.
                </p>
                <p>
                  <b>AgriCo Hub</b> serves as an entrepreneurial haven for young
                  entrepreneurs, innovators and women in under-served
                  communities who seek to pursue their entrepreneurial dream.
                  The Hub parades a team of qualified professionals and
                  technical support staff who have over 10 years of experience
                  in professional, vocational, and technical training in youth
                  entrepreneurship development, IT innovations, Agribusiness,
                  and related enterprise areas.
                </p>
                <p>
                  <b>The Hub</b> has implemented several Entrepreneurship,
                  Business Development and Digitalization programs in
                  partnership with local and international partners including
                  GIZ, Plan Ghana, SE Ghana, Ghana Hubs Network, Reach4Change,
                  Ghana Enterprises Agency, Ghana Chamber of Entrepreneurs with
                  Disability, UNPD Ghana, NYA, Ghana Tech Lab/MasterCard
                  Foundation and Soronko Academy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutText;
