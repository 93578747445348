import React from "react";
import Footer from "../Home/Footer";
import Header from "../Home/header";
import MainContent from "../Home/MainContent";
import BreadCrump from "../../component/Global/Breadcrump";

function About() {
  return (
    <div className="about">
      <Header />
      <BreadCrump currentPage={"About Us"} />
      <MainContent />
      <Footer />
    </div>
  );
}

export default About;
