//Mobile App Training Image
import mba1 from "../../images/gallery/Mobile App/1.jpeg";
import mba2 from "../../images/gallery/Mobile App/5.jpg";
import mba3 from "../../images/gallery/Mobile App/3.jpg";
import mba4 from "../../images/gallery/Mobile App/4.jpg";
import mba6 from "../../images/gallery/Mobile App/7.jpg";
import mba5 from "../../images/gallery/Mobile App/2_1.jpg";
import mba7 from "../../images/gallery/Mobile App/3_1.jpg";
import mba8 from "../../images/gallery/Mobile App/8.jpg";
import mba9 from "../../images/gallery/Mobile App/9.jpg";
import mba10 from "../../images/gallery/Mobile App/11.jpg";
// Digital Media Training
import dm1 from "../../images/gallery/Digital Media/1.jpg";
import dm2 from "../../images/gallery/Digital Media/2.jpg";
import dm3 from "../../images/gallery/Digital Media/3.jpg";
import dm4 from "../../images/gallery/Digital Media/4.jpg";
import dm5 from "../../images/gallery/Digital Media/5.jpg";
import dm6 from "../../images/gallery/Digital Media/6.jpg";
import dm7 from "../../images/gallery/Digital Media/7.jpg";
import dm8 from "../../images/gallery/Digital Media/8.jpg";
// import dm9 from "../../images/gallery/Digital Media/9.jpg";
// import dm10 from "../../images/gallery/Digital Media/10.jpg";
// Acceleration Images
import acc1 from "../../images/gallery/Acceleration/1.jpg";
import acc2 from "../../images/gallery/Acceleration/2.jpg";
import acc3 from "../../images/gallery/Acceleration/3.jpg";
import acc4 from "../../images/gallery/Acceleration/12.jpg";
import acc5 from "../../images/gallery/Acceleration/5.jpg";
import acc6 from "../../images/gallery/Acceleration/6.jpg";
import acc7 from "../../images/gallery/Acceleration/7.jpg";
import acc8 from "../../images/gallery/Acceleration/8.jpg";
import acc9 from "../../images/gallery/Acceleration/11.jpg";
import acc10 from "../../images/gallery/Acceleration/10.jpg";
//
//incubation images
import incu1 from "../../images/gallery/Incubation/1.jpg";
import incu2 from "../../images/gallery/Incubation/2.jpg";
import incu3 from "../../images/gallery/Incubation/3.jpg";
import incu4 from "../../images/gallery/Incubation/4.jpg";
import incu5 from "../../images/gallery/Incubation/5.jpg";
import incu6 from "../../images/gallery/Incubation/6.jpg";
import incu7 from "../../images/gallery/Incubation/7.jpg";
import incu8 from "../../images/gallery/Incubation/13.jpg";
import incu9 from "../../images/gallery/Incubation/9.jpg";
import incu10 from "../../images/gallery/Incubation/14.jpg";
// web app development
import webapp1 from "../../images/gallery/Web App/2.jpg";
import webapp2 from "../../images/gallery/Web App/3.jpg";
import webapp3 from "../../images/gallery/Web App/4.jpg";
import webapp4 from "../../images/gallery/Web App/5.jpg";
import webapp5 from "../../images/gallery/Web App/6.jpg";
import webapp6 from "../../images/gallery/Web App/7.jpg";
import webapp7 from "../../images/gallery/Web App/8.jpg";
import webapp8 from "../../images/gallery/Web App/9.jpg";
import webapp9 from "../../images/gallery/Web App/10.jpg";
import webapp10 from "../../images/gallery/Web App/11.jpg";

var imageList = [
  {
    image: {
      url: dm1,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm2,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm3,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm4,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm5,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm3,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm6,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm7,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm8,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: dm1,
      dataName: "dm-training",
    },
  },
  {
    image: {
      url: mba1,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba2,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba3,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba4,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba5,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba6,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba7,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba8,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba9,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: mba10,
      dataName: "mba-training",
    },
  },
  {
    image: {
      url: incu1,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu2,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu3,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu4,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu5,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu6,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu7,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu8,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu9,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: incu10,
      dataName: "business-incubation",
    },
  },
  {
    image: {
      url: acc1,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc2,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc3,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc4,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc5,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc6,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc7,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc8,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc9,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: acc10,
      dataName: "business-acceleration",
    },
  },
  {
    image: {
      url: webapp1,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp2,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp3,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp4,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp5,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp6,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp7,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp8,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp9,
      dataName: "web-training",
    },
  },
  {
    image: {
      url: webapp10,
      dataName: "web-training",
    },
  },
];
export { imageList };
