import Footer from "../Home/Footer";
import Header from "../Home/header";
import { Component } from "react";
import { Pagination, Box } from "@mui/material";
import BreadCrump from "../../component/Global/Breadcrump";
import { imageList as imageData } from "./gallery-data";

import { Select } from "@mui/material";
import "./gallery.css";
import { CloseOutlined } from "@material-ui/icons";
// import gallery data

export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      // TODO: Items per page should be dynamically rendered.
      // It will be dynamically related to the total number of items in the filter btn
      itemsPerPage: 50,
      imageData: imageData.map((data) => data.image),
    };
  }
  //

  componentDidMount() {
    //selecting all required elements
    const filterItem = document.querySelector(".items");
    const filterImg = document.querySelectorAll(".gallery .image");
    const filterSelector = document.querySelector("#filter-selector");
    //
    filterSelector.onchange = (event) => {
      let dataName =
        filterSelector.options[filterSelector.selectedIndex].getAttribute(
          "data-name"
        );
      let tempData = imageData.map((data) => data.image.dataName);
      //
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i] === dataName || dataName === "all") {
          filterImg[i].classList.remove("hide"); //
          filterImg[i].classList.add("show");
        } else {
          filterImg[i].classList.remove("show"); //
          filterImg[i].classList.add("hide");
        }
      }
    };
    //

    filterItem.onclick = (selectedItem) => {
      //if user click on filterItem div
      if (selectedItem.target.classList.contains("item")) {
        //if user selected item has .item class
        filterItem.querySelector(".active").classList.remove("active"); //remove the active class which is in first item
        selectedItem.target.classList.add("active"); //add that active class on user selected item
        let filterName = selectedItem.target.getAttribute("data-name"); //getting data-name value of user selected item and store in a filtername variable
        filterImg.forEach((image) => {
          console.log(filterImg.length);
          let filterImages = image.getAttribute("data-name"); //getting image data-name value
          //if user selected item data-name value is equal to images data-name value
          //or user selected item data-name value is equal to "all"
          if (filterImages === filterName || filterName === "all") {
            image.classList.remove("hide"); //first remove the hide class from the image
            image.classList.add("show"); //add show class in image
          } else {
            image.classList.add("hide"); //add hide class in image
            image.classList.remove("show"); //remove show class from the image
          }
        });
      }
    };
    for (let i = 0; i < filterImg.length; i++) {
      filterImg[i].setAttribute("onclick", (element) => {
        const previewBox = document.querySelector(".preview-box"),
          categoryName = previewBox.querySelector(".title p"),
          previewImg = previewBox.querySelector("img"),
          closeIcon = previewBox.querySelector(".icon"),
          shadow = document.querySelector(".shadow");
        //
        document.querySelector("body").style.overflow = "hidden";
        let selectedPrevImg = element.querySelector("img").src; //getting user clicked image source link and stored in a variable
        let selectedImgCategory = element.getAttribute("data-name"); //getting user clicked image data-name value
        previewImg.src = selectedPrevImg; //passing the user clicked image source in preview image source
        categoryName.textContent = selectedImgCategory; //passing user clicked data-name value in category name
        previewBox.classList.add("show"); //show the preview image box
        shadow.classList.add("show"); //show the light grey background
        closeIcon.onclick = () => {
          //if user click on close icon of preview box
          previewBox.classList.remove("show"); //hide the preview box
          shadow.classList.remove("show"); //hide the light grey background
          document.querySelector("body").style.overflow = "auto"; //show the scroll bar on body
        };
      }); //adding onclick attribute in all available images
    }
  }

  // end componentDidMount
  render() {
    const { imageData, itemsPerPage, currentPage } = this.state;

    // Logic for displaying items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Object.entries(imageData).slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(Object.keys(imageData).length / itemsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderItems = currentItems.map(([key, value]) => (
      <div key={key}>
        <div key={key} className="image" data-name={value.dataName}>
          <span>
            <img
              src={value.url}
              alt={""}
              onClick={(event) => {
                const previewBox = document.querySelector(".preview-box"),
                  // categoryName = previewBox.querySelector(".cat-title p"),
                  previewImg = previewBox.querySelector("img"),
                  closeIcon = previewBox.querySelector(".close-icon"),
                  shadow = document.querySelector(".shadow");
                // //
                // categoryName.innerHTML = value.dataName;
                // //
                document.querySelector("body").style.overflow = "hidden";
                let selectedPrevImg = event.target.src; //getting user clicked image source link and stored in a variable
                previewImg.src = selectedPrevImg; //passing the user clicked image source in preview image source
                previewBox.classList.add("show"); //show the preview image box
                shadow.classList.add("show"); //show the light grey background
                closeIcon.onclick = () => {
                  //if user click on close icon of preview box
                  previewBox.classList.remove("show"); //hide the preview box
                  shadow.classList.remove("show"); //hide the light grey background
                  document.querySelector("body").style.overflow = "auto"; //show the scroll bar on body
                };
              }}
            />
          </span>
        </div>
      </div>
    ));

    return (
      <div className="vw-100">
        <Header />
        <BreadCrump currentPage={"Gallery"} />
        <section className="gallery-container container">
          <section className="gallery__img-container">
            <div className="wrapper">
              {/* filter Items  */}
              <div className=" text-center heading-section pt-5">
                <h3 className="fw-bolder fw-bold">
                  <span className="header text-info">Project Gallery</span>
                </h3>
                <h2 className="mb-4">Gallery</h2>
              </div>
              {/* Filtering buttons */}
              <nav className="mt-5">
                <div className="items desktop-filter">
                  <span className="item active" data-name="all">
                    All
                  </span>
                  <span className="item" data-name="dm-training">
                    Digital Media Training
                  </span>
                  <span className="item" data-name="web-training">
                    Web Development Training
                  </span>
                  <span className="item" data-name="mba-training">
                    Mobile App Dev. Training
                  </span>
                  <span className="item" data-name="business-incubation">
                    Business Incubation
                  </span>
                  <span className="item" data-name="business-acceleration">
                    Business Acceleration
                  </span>
                </div>
                <div className="mobile-filter">
                  <h2 className="subtitle callout">Filter By:</h2>
                  <Select
                    native={true}
                    id="filter-selector"
                    className="filter-selector"
                    title="Select Category to filter"
                  >
                    <option value="All" data-name="all">
                      All
                    </option>
                    <option
                      value="Digital Media Training"
                      data-name="dm-training"
                    >
                      Digital Media Training
                    </option>
                    <option
                      value="Web Development Training"
                      data-name="web-training"
                    >
                      Web Development Training
                    </option>
                    <option
                      value="Mobile App Dev. Training"
                      data-name="mba-training"
                    >
                      Mobile App Dev. Training
                    </option>
                    <option
                      value="Business Acceleration"
                      data-name="business-acceleration"
                    >
                      Business Acceleration
                    </option>
                    <option
                      value="Business Incubation"
                      data-name="business-incubation"
                    >
                      Business Incubation
                    </option>
                  </Select>
                </div>
              </nav>
              {/* Render Image */}
              <div className="gallery mb-5">{renderItems}</div>
            </div>

            <div className="preview-box">
              <div className="details">
                <span className="close-icon">
                  <CloseOutlined fontSize="large" />
                </span>
              </div>
              <div className="image-box">
                <img src="" alt="" />
              </div>
            </div>
            <div className="shadow"></div>

            <Box mb="2rem">
              <Pagination
                count={pageNumbers.length}
                size="large"
                showFirstButton
                showLastButton
                shape="rounded"
                color="standard"
                page={currentPage}
                onClick={(event) => {
                  return this.setState({
                    currentPage: Number(event.target.innerText),
                  });
                }}
              ></Pagination>
            </Box>
          </section>
        </section>

        <Footer />
      </div>
    );
  }
}
