import React from "react";
import Body from "./body";
import Header from "./header";

export default function Home() {
  return (
    <div className="">
      <Header />
      <Body />
    </div>
  );
}
